import React from 'react';
import { graphql, Link } from 'gatsby';

import scrollTo from 'gatsby-plugin-smoothscroll';

import SEO from "../../../components/seo";
import Header from '../../../components/Header';
import KnowledgeCenter from '../../../components/KnowledgeCenter';
import Footer from '../../../components/Footer';
import StrapiContent from '../../../components/StrapiContent';
import GoTop from '../../../components/GoTop';
import GoTopMobile from '../../../components/GoTopMobile';
import GlobalStyles from '../../../styles/GlobalStyles';

import content from './content';
import icons from './icons';

import { 
  Wrapper, 
  Title,
  ContainerMenu,
  CardMenu,
  CardTitle,
  Container,
  CompanyInfo,
  AboutUs,
  Policy,
  Services,
  Dimension,
} from './styles';

const CarriersAbout = ({ data }) => {
  const carrier = data.strapi.carrier;

  return (
    <>
      <SEO 
        title={`SmartEnvios: ${carrier.title}`}
      />
      <Header />
      <KnowledgeCenter />

      <Wrapper id="doc">
        <Title>TRANSPORTADORA <span>{carrier.title.toUpperCase()}</span></Title>

        <ContainerMenu>
          {content.filter(e => e.name !== 'Voltar').map((menu, index) => {
            const Icon = icons[menu.label];
            
            return (
              <CardMenu key={index} onClick={() => scrollTo(`${menu.url}`)}>
                <Icon />
                <CardTitle>{menu.name}</CardTitle>
              </CardMenu>
            )
          })}

          {content.filter(e => e.name === 'Voltar').map((menu, index) => {
            const Icon = icons[menu.label];
            
            return (
              <Link key={index} to={`${menu.url}`}>
                <CardMenu>
                  <Icon />
                  <CardTitle>{menu.name}</CardTitle>
                </CardMenu>
              </Link>
            )
          })}
        </ContainerMenu>
        
        <Container>
          <AboutUs>
            <CompanyInfo>SOBRE</CompanyInfo>
            <StrapiContent 
              content={carrier.about}
            />
          </AboutUs>
          
          <Policy id="politicas">
            <CompanyInfo>POLÍTICAS</CompanyInfo>
            <StrapiContent 
              content={carrier.policy}
            />
          </Policy>
          
          <Services id="servicos">
            <CompanyInfo>SERVIÇOS</CompanyInfo>
            <StrapiContent 
              content={carrier.service}
            />
          </Services>
          
          <Dimension id="dimensoes">
            <CompanyInfo>DIMENSÕES</CompanyInfo>
            <StrapiContent 
              content={carrier.dimension}
            />
          </Dimension>
        </Container>

      </Wrapper>

      <Footer />
      <GoTop />
      <GoTopMobile />
      <GlobalStyles />
    </>
  );
}

export default CarriersAbout;

export const query = graphql`
  query AboutCarrier($id: ID!) {
    strapi {
      carrier(id: $id) {
        title
        about
        policy
        service
        dimension
      }
    }
  }
`;
const menu = [
  {
    name: 'Políticas',
    label: 'policy',
    url: '#politicas'
  },
  {
    name: 'Serviços',
    label: 'services',
    url: '#servicos'
  },
  {
    name: 'Dimensões',
    label: 'dimensions',
    url: '#dimensoes'
  },
  {
    name: 'Voltar',
    label: 'goback',
    url: '/transportadoras'
  }
];

export default menu;
import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 20px 200px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1070px) {
    padding: 20px 100px;
  }

  @media (max-width: 670px) {
    padding: 20px 50px;
  }

  @media (max-width: 300px) {
    padding: 20px 10px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  color: #044D75;
  font-family: 'Sora', sans-serif;
  font-size: 40px;
  padding: auto;

  > span {
    color: #EE7600;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }

  @media (max-width: 300px) {
    font-size: 26px;
  }
`;

export const ContainerMenu = styled.div`
  margin-top: 40px;
  display: grid;
  width: auto;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 640px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const CardMenu = styled.div`
  background: #fff;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  }
`;

export const CardTitle = styled.p`
  margin-top: 30px;
  font-size: 20px;
  font-family: 'Sora', sans-serif;
  color: #EE7600;
`;

export const Container = styled.section`
  margin-top: 40px;
`;

export const CompanyInfo = styled.h3`
  font-size: 25px;
  font-family: 'Sora', sans-serif;
  font-weight: bold;
  color: #044D75;

  @media (max-width: 670px) {
    margin-bottom: 20px;
  }
`;

export const AboutUs = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 4fr;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 3fr;
  }

  @media (max-width: 1070px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Policy = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 4fr;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 3fr;
  }

  @media (max-width: 1070px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Services = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 4fr;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 3fr;
  }

  @media (max-width: 1070px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Dimension = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 4fr;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 3fr;
  }

  @media (max-width: 1070px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
  }
`;

import policy from '../../../assets/images/policy.svg';
import services from '../../../assets/images/services.svg';
import dimensions from '../../../assets/images/dimensions.svg';
import goback from '../../../assets/images/goback.svg';

const icons = {
  policy,
  services,
  dimensions,
  goback
};

export default icons;